.App {
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: center;
  font-size: 20px;
  background-color: #DDDFE4;
  height: 100vh;
}

h2 {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0;
  font-size: 32px;
  font-weight: 500;
}

.container {
  width: 100vw;
  max-width: 100%;
  margin-top: 0;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-content: center;
  align-items: flex-start;
}

.input-form {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  height: 100px;
  padding-top: 1.5em;
}

.input-field {
  width: 300px;
  height: 30px;
  border-radius: 0.3em;
}

.input-btn {
  width: 100px;
  height: 35px;
  border-radius: 0.3em;
  margin: auto;
  background-color: #BDBF09;
  color: #fff;
  border: 0.5px solid #BDBF09;
}

.input-btn:hover {
  background-color: #BDBF09;
  border: 0.5px solid #BDBF09;
  transform: scale(1.1);
}

.list {
  overflow: auto;
  border-collapse: collapse;
  margin-top:  150px;
  width: 100%;
}

.list-items  tr {
  height: 60px;
  width: 500px;
}

.list-items td {
  text-align: justify;
  padding-right: 2em;
  padding-left: 2em;
  padding-bottom: 1em;
}

.list tr:nth-child(odd) {
  background-color: #959e9e21;
}

.mark-done {
  text-decoration: line-through;
}
